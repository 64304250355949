import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import axios from 'axios'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import { Box, Flex, DesktopContainer, MobileContainer, ResponsiveColRow1000 } from '../components/ui/Div'
import Button, { PillButton } from '../components/ui/Button'
import Spinner from '../components/ui/Spinner'
import { colors, smallBoxShadow, shuffle } from '../components/ui/helpers.js'
import { urlToString, stringToUrl } from '../components/ui/formatters.js'
import countryToIso2 from '../../constants/countryToIso2'
import { H1, H2, H3, H4, SeoH2, SeoH4, Span, Text, LinkSpan } from '../components/ui/Text.js'
import OnlineIcon from '../components/ui/OnlineIcon'
import Image from '../components/ui/Image'
import Layout from '../components/layout'
import Helmet from '../components/Helmet'
import Footer from '../components/Footer'
import Header from '../components/Header'
import telephones from '../../constants/telephones'
import searches from '../../constants/searches'
import useCases from '../../constants/useCases'

const HeroContainer = styled(Flex)`
  position: relative;
  margin-bottom: 200px;
  background: rgb(104, 65, 198);
  background: linear-gradient(180deg, rgba(104, 65, 198, 1) 0%, rgba(90, 57, 171, 1) 100%);
  min-height: 600px;
  border-radius: 32px;
  flex-direction: column;
  padding-top: 120px;
  padding-left: 120px;
  padding-right: 120px;
  padding-bottom: 100px;
  align-items: center;
  -webkit-box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
  @media (max-width: 700px) {
    min-height: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 60px;
    padding-bottom: 150px;
    height: auto;
  }
`

const Container = styled(Flex)`
  background-color: white;
  padding: 24px;
  flex-direction: column;
  border-radius: 24px;
`

const PagePadding = styled(Flex)`
  flex-direction: column;
  padding-left: 100px;
  padding-right: 100px;
  @media (max-width: 700px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const FlagImage = styled.img`
  border-radius: 8px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.3);
`
const Grid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const CountryContainer = styled(Flex)`
  background-color: white;
  border-radius: 24px;

  cursor: pointer;
  position: relative;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  &&:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  }
`

const NumberGrid = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 450px) {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
`

const TelephoneContainer = styled(Flex)`
  position: relative;
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  flex-direction: column;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

  &:hover {
    -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 500px) {
    margin-top: 8px;
    margin-bottom: 8px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
`

const HowImageContainer = styled(Flex)`
  width: 300px;
  min-width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 16px;
`

const Flag = ({ iso2 }) => {
  return <FlagImage alt={`${iso2} flag`} src={`/country-flag/${iso2}.svg`} width="60px" height="60px" />
}

const IndexPage = () => {
  return (
    <Layout bg="#F6F8F7">
      <Helmet
        title={`Receive SMS Online 100% Free | Exclusive Phone numbers | SMS-Temp`}
        htmlAttributes={{
          lang: 'en'
        }}
        description={`Protect your privacy and security with the internets best free temporary phone numbers service. Receive unlimited SMS on exclusive, real SIM numbers.`}
        url="https://sms-temp.com/"
      >
        <html lang="en" />
      </Helmet>
      <Header />

      <PagePadding alignItems="center">
        <HeroContainer maxWidth="1200px">
          <H1 textAlign="center" fontSize="75px" lineHeight="80px" fontSizem="40px" lineHeightm="44px" fontWeight="700" color="#FFFAFF" mb="32px">
            Receive SMS Online
            <Span
              textAlign="center"
              display="block"
              lineHeight="80px"
              fontSize="75px"
              fontSizem="40px"
              lineHeightm="44px"
              fontWeight="700"
              color="#E9D7FD"
            >
              100% Free
            </Span>
          </H1>
          <H2 textAlign="center" color="#E9D7FD" lineHeight="40px" fontSizem="20px" lineHeightm="24px">
            Protect your privacy and security with our free temporary phone numbers service. Receive unlimited SMS on exclusive, real SIM numbers.
          </H2>
        </HeroContainer>

        <ResponsiveColRow1000 zIndex={1} px="40px" pxm="0px" mt="-300px" mb="100px" justifyContent="space-between" maxWidth="1200px">
          <Flex flexDirection="column" mr="48px">
            <Grid mb="60px" height="fit-content">
              {Object.keys(telephones).map((otherCountry) => {
                const numMessages = 5000 - Number(telephones[otherCountry][0].slice(-3)) * 5
                return (
                  <a key={otherCountry} href={`/${otherCountry}/`}>
                    <CountryContainer>
                      <Flex>
                        <Flag iso2={countryToIso2[otherCountry]} />
                        <Flex flexDirection="column" justifyContent="center">
                          <Text mb="8px" fontWeight="500" fontSize="20px" px="16px" mbtextAlign="center" A>
                            {urlToString(otherCountry)}
                          </Text>
                          <Text fontSize="16px" px="16px" mbtextAlign="center" A>
                            Active numbers: {telephones[otherCountry].length}
                          </Text>
                          <Text fontSize="16px" px="16px" mbtextAlign="center" A>
                            Messages Received: {numMessages}
                          </Text>
                        </Flex>
                        <OnlineIcon />
                      </Flex>
                    </CountryContainer>
                  </a>
                )
              })}
            </Grid>
            <Flex mb="60px" flexDirection="column">
              <H3 mb="16px">Why use a temporary phone number?</H3>
              <Text>
                Disposable phone numbers are a great way to protect your privacy and stay safe online. Sign up for services, register for websites,
                and purchase items without the need to reveal your real phone number or personal information. Temp numbers can also be used to verify
                online accounts or receive text messages without the risk of being spammed or having your personal information exposed.
              </Text>
            </Flex>
            <Flex flexDirection="column" mb="48px">
              <H3 mb="16px">Popular Numbers</H3>
              <NumberGrid>
                {shuffle(Object.keys(telephones)).map((key) => {
                  return telephones[key].map((telephone, i) => {
                    const numMessages = 5000 - Number(telephone.slice(-3)) * 5
                    return (
                      <a key={telephone} href={`/${key}/${telephone}/`}>
                        <TelephoneContainer>
                          <Flex alignItems="center">
                            <Flag iso2={countryToIso2[key]} />
                            <Flex flexDirection="column" ml="16px">
                              <Text width="100%" fontSize="26px" textAlign="left" mr={2} mb="8px">
                                +{telephone}
                              </Text>
                              <Text>Messages received: {numMessages}</Text>
                            </Flex>
                            <OnlineIcon />
                          </Flex>
                        </TelephoneContainer>
                      </a>
                    )
                  })
                })}
              </NumberGrid>
            </Flex>
            <Flex mb="48px" flexDirection="column">
              <H3 mb="16px">How to use our exclusive numbers?</H3>
              <Flex mb="32px" alignItems="center" flexDirectionm="column">
                <Text mb="16px">
                  Select the country you would like your temporary number to be from. We currently support numbers from the United States, the United
                  Kingdom, Australia and Canada
                </Text>
                <HowImageContainer mxm="0px" ml="24px">
                  <StaticImage alt="how to image 1" src="../images/how1.png" placeholder="blurred" layout="fixed" width={300} height={200} />
                </HowImageContainer>
              </Flex>
              <Flex mb="32px" alignItems="center" flexDirectionm="column-reverse">
                <HowImageContainer mxm="0px" mr="24px">
                  <StaticImage alt="how to image 2" src="../images/how2.png" placeholder="blurred" layout="fixed" width={300} height={200} />
                </HowImageContainer>
                <Text mb="16px">
                  Select a temporary number. You can choose from a range of available numbers all are real SIM mobile or local numbers, pick one that
                  works for you.
                </Text>
              </Flex>
              <Flex mb="32px" alignItems="center" flexDirectionm="column">
                <Text mb="16px">
                  Use the temporary number to create an account on a website, receive verification codes for two-factor authentication etc.
                </Text>
                <HowImageContainer mxm="0px" ml="24px">
                  <StaticImage alt="how to image 3" src="../images/how3.png" placeholder="blurred" layout="fixed" width={300} height={200} />
                </HowImageContainer>
              </Flex>
              <Flex mb="32px" alignItems="center" flexDirectionm="column-reverse">
                <HowImageContainer mxm="0px" mr="24px">
                  <StaticImage alt="how to image 4" src="../images/how4.png" placeholder="blurred" layout="fixed" width={300} height={200} />
                </HowImageContainer>
                <Text mb="16px">Press refresh messages until your sms message appears. Average wait time is under 4 seconds.</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column" width="300px" minWidth="300px" widthm={'100%'}>
            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Searches
              </Text>
              <Flex flexWrap="wrap">
                {searches.map((search) => {
                  return (
                    <a href={`/${stringToUrl(search)}/`}>
                      <PillButton mb="16px" mr="16px" label={search} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <a href={`/united-states/${telephones['united-states'][1]}`}>
              <Container flexWrap="wrap" mb="60px">
                <Text mb="16px" fontSize="18px" fontWeight="500">
                  Newest SMS Number
                </Text>
                <Flex position="relative">
                  <Flex alignItems="center">
                    <Flag iso2={'US'} />
                    <Flex flexDirection="column" ml="16px">
                      <Text width="100%" fontSize="22px" textAlign="left" mr={2} mb="8px">
                        +{telephones['united-states'][1]}
                      </Text>
                      <Text>
                        Created: <Span color={colors.fontLight}>1 day ago</Span>
                      </Text>
                    </Flex>
                    <OnlineIcon />
                  </Flex>
                </Flex>
              </Container>
            </a>

            <Container flexWrap="wrap" mb="60px">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Use Cases
              </Text>
              <Flex flexWrap="wrap">
                {useCases.map((useCase) => {
                  return (
                    <a href={`/${stringToUrl(useCase)}/`}>
                      <PillButton mb="16px" mr="16px" label={useCase} />
                    </a>
                  )
                })}
              </Flex>
            </Container>

            <Container flexWrap="wrap">
              <Text mb="16px" fontSize="18px" fontWeight="500">
                Countries
              </Text>
              <Flex flexWrap="wrap">
                {Object.keys(telephones).map((country) => {
                  return (
                    <a href={`/${country}/`}>
                      <PillButton mb="16px" mr="16px" label={urlToString(country)} />
                    </a>
                  )
                })}
              </Flex>
            </Container>
          </Flex>
        </ResponsiveColRow1000>
      </PagePadding>
      <Footer iso2={'en'} />
    </Layout>
  )
}

export default IndexPage
